import { useMemo, useRef, useState } from "react";
import { flushSync } from "react-dom";
import { useSearchParams } from "react-router-dom";
import { File } from "../types/api/files";
import { reduceSeriesKeys } from "../types/api/series";
import useLogParams, { NormalizedParam } from "./use-log-params";

const DefaultAutoScaleMode = true;
const DefaultSplitMode = false;

export default function useChart(id: string, file: File) {
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * Parse the series keys available for this chart based on the file provided.
   */
  const seriesKeys = useMemo(() => {
    return Array.from(reduceSeriesKeys(file.data));
  }, [file.data]);

  /**
   * Use ref for the searchParams
   */
  const yAxisRef = useRef(searchParams.get(`${id}-yAxis`) ?? undefined);
  const xAxisRef = useRef(searchParams.get(`${id}-xAxis`) ?? undefined);

  /**
   * Normalize the series keys into human readable format & get the defaults for
   * X & Y
   */
  const { normalized, defaultYAxis, defaultXAxis } = useLogParams(
    seriesKeys,
    yAxisRef.current,
    xAxisRef.current
  );

  /**
   * Internal state of a chart
   */
  const [autoScale, setAutoScale] = useState<boolean>(
    searchParams.has(`${id}-autoScale`)
      ? searchParams.get(`${id}-autoScale`) === "true"
      : DefaultAutoScaleMode
  );
  const [split, setSplit] = useState<boolean>(
    searchParams.has(`${id}-split`)
      ? searchParams.get(`${id}-split`) === "true"
      : DefaultSplitMode
  );

  const [yAxis, setYAxis] = useState<NormalizedParam[] | undefined>(
    defaultYAxis
  );
  const [xAxis, setXAxis] = useState<NormalizedParam | null | undefined>(
    defaultXAxis
  );

  const hasDefaultOptions = useMemo(
    () =>
      autoScale === DefaultAutoScaleMode &&
      split === DefaultSplitMode &&
      yAxis === defaultYAxis &&
      xAxis === defaultXAxis,
    [autoScale, split, yAxis, xAxis, defaultXAxis, defaultYAxis]
  );

  /**
   * State modifying functions
   */
  const toggleAutoScale = () => {
    flushSync(() => {
      setSearchParams((prev) => {
        prev.set(`${id}-autoScale`, String(!autoScale));
        return prev;
      });
      setAutoScale(!autoScale);
    });
  };

  const toggleSplit = () => {
    flushSync(() => {
      setSearchParams((prev) => {
        prev.set(`${id}-split`, String(!split));
        return prev;
      });
      setSplit(!split);
    });
  };

  const handleXAxisChange = (param: NormalizedParam | null) => {
    flushSync(() => {
      setSearchParams((prev) => {
        if (param === null) {
          prev.delete(`${id}-xAxis`);
        } else {
          prev.set(`${id}-xAxis`, param.key);
        }

        return prev;
      });
      setXAxis(param);
    });
  };

  const handleYAxisChange = (params: NormalizedParam[]) => {
    flushSync(() => {
      setSearchParams((prev) => {
        const indicies = params
          .map((param) => normalized.findIndex((n) => n.key === param.key))
          .filter((idx) => idx !== -1);

        if (indicies.length === 0) {
          prev.delete(`${id}-yAxis`);
        } else {
          prev.set(`${id}-yAxis`, indicies.join(","));
        }

        return prev;
      });
      setYAxis(params);
    });
  };

  const handleResetOptions = () => {
    flushSync(() => {
      setSearchParams((prev) => {
        prev.delete(`${id}-yAxis`);
        prev.delete(`${id}-xAxis`);
        prev.delete(`${id}-autoScale`);
        prev.delete(`${id}-split`);
        return prev;
      });
      setYAxis(defaultYAxis);
      setXAxis(defaultXAxis);
      setAutoScale(DefaultAutoScaleMode);
      setSplit(DefaultSplitMode);
    });
  };

  return {
    hasDefaultOptions,
    normalized,
    autoScale,
    split,
    yAxis,
    xAxis,
    toggleAutoScale,
    toggleSplit,
    handleXAxisChange,
    handleYAxisChange,
    handleResetOptions,
  };
}
