import { Deselect, FilterList, SelectAll } from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Grid2,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Modal,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { ChangeEvent, useMemo, useRef, useState } from "react";
import { flushSync } from "react-dom";
import { NormalizedParam } from "../hooks/use-log-params";

export interface ParameterSelectorProps {
  value: NormalizedParam[];
  params: NormalizedParam[];
  onChange: (params: NormalizedParam[]) => void;
}

export default function ParameterSelector({
  params,
  value,
  onChange,
}: ParameterSelectorProps) {
  const theme = useTheme();
  const [query, setQuery] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const list = useRef<HTMLDivElement>(null);

  const filtered = useMemo(() => {
    return params.filter((param: NormalizedParam) =>
      param.name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
    );
  }, [query, params]);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => {
    setModalOpen(false);
    setQuery("");
  };

  const handleSelect = (param: NormalizedParam) => {
    if (value.some((p) => p.key === param.key)) {
      return onChange(value.filter((v) => v.key !== param.key));
    }

    onChange([...value, param]);
  };

  const handleSelectAll = () => {
    if (value.length > 0) {
      return onChange([]);
    }

    onChange(params);
  };

  const handleQueryChange = (el: ChangeEvent<HTMLInputElement>) => {
    flushSync(() => {
      setQuery(el.target.value);
    });

    if (list.current === null) {
      return;
    }

    if (list.current.scrollTo !== undefined) {
      list.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <>
      <Tooltip title="Add Parameters to Chart">
        <IconButton onClick={handleModalOpen}>
          <Badge
            anchorOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            color="primary"
            badgeContent={value.length}
          >
            <FilterList />
          </Badge>
        </IconButton>
      </Tooltip>
      <Modal keepMounted open={modalOpen} onClose={handleModalClose}>
        <Box sx={{ height: "100vh" }} component={Paper}>
          <Grid2
            container
            padding={1}
            spacing={1}
            alignItems="center"
            sx={{
              position: "fixed",
              zIndex: 10,
              background: `${theme.palette.background.default}!important`,
              bottom: 0,
              width: "100%",
              borderTop: {
                borderColor: theme.palette.divider,
                borderWidth: 1,
                borderStyle: "solid",
              },
            }}
          >
            <Grid2 flexGrow={1}>
              <TextField
                label="Search Parameters"
                size="small"
                placeholder="Search..."
                onChange={handleQueryChange}
                value={query}
                sx={{ width: "100%" }}
                autoFocus
              />
            </Grid2>
            <Grid2>
              <IconButton
                title={value.length > 0 ? "Deselect All" : "Select All"}
                onClick={handleSelectAll}
              >
                {value.length > 0 ? <Deselect /> : <SelectAll />}
              </IconButton>
            </Grid2>
            <Grid2>
              <Badge color="secondary" badgeContent={value.length}>
                <Button variant="contained" onClick={handleModalClose}>
                  Confirm
                </Button>
              </Badge>
            </Grid2>
          </Grid2>
          <Grid2
            container
            sx={{
              maxHeight: "calc(100vh - 60px)",
              overflowY: "scroll",
            }}
            ref={list}
          >
            {filtered.length === 0 && (
              <Grid2 size={{ xs: 12 }} textAlign="center" padding={2}>
                <Typography variant="body1">
                  No parameters containing query "{query}".
                </Typography>
              </Grid2>
            )}
            {filtered.map((param: NormalizedParam) => (
              <Grid2 key={param.key} size={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
                <ListItem>
                  <ListItemButton onClick={() => handleSelect(param)}>
                    <ListItemText>{param.name}</ListItemText>
                    <Checkbox
                      role="checkbox"
                      checked={value.some((p) => p.key === param.key)}
                    />
                  </ListItemButton>
                </ListItem>
              </Grid2>
            ))}
          </Grid2>
        </Box>
      </Modal>
    </>
  );
}
