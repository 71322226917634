export type Series = Point[];

export interface Point {
  [key: string]: number | string;
}

export function reduceSeriesKeys(series?: Series) {
  const keys = new Set<string>([]);

  if (series !== undefined) {
    series.forEach((point) => {
      Object.keys(point).forEach((key) => keys.add(key));
    });
  }

  return keys;
}
