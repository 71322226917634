import { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { File } from "../types/api/files";
import useAxios from "./use-axios";

export default function useFile(
  id: string | undefined,
  refreshInterval?: number
) {
  const axios = useAxios();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError>();
  const [data, setData] = useState<File>({} as File);

  const getFile = useCallback(
    (signal: AbortSignal) => {
      if (id === undefined) {
        return;
      }

      axios(signal)
        .get(`/v1/files/${id}`)
        .then(({ data }: { data: File }) => {
          setData(data);
        })
        .catch((error: AxiosError) => {
          if (error.code === "ERR_CANCELED") {
            return;
          }

          setError(error);
        })
        .finally(() => setLoading(false));
    },
    [id, axios]
  );

  /** effect to poll when the file is still processing */
  useEffect(() => {
    if (refreshInterval !== undefined && data.processed_at === null) {
      const controller = new AbortController();
      const interval = setInterval(
        () => getFile(controller.signal),
        refreshInterval
      );

      return () => {
        controller.abort();
        clearInterval(interval);
      };
    }
  }, [refreshInterval, data.processed_at, getFile]);

  /** effect to get file on change */
  useEffect(() => {
    setLoading(true);
    setError(undefined);

    const controller = new AbortController();
    getFile(controller.signal);

    return () => {
      controller.abort();
    };
  }, [getFile]);

  return { loading, error, data };
}
