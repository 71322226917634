import { useMemo } from "react";

export interface NormalizedParam {
  key: string;
  name: string;
}

const yAxisSearches = [/.*(engine.*speed).*/i, /.*(boost).*/i, /.*(rpm).*/i];
const xAxisSearches = [/.*(engine.*speed).*/i, /.*(rpm).*/i, /.*(time).*/i];

export default function useLogParams(
  params: string[],
  yAxis?: string,
  xAxis?: string
) {
  const normalized: NormalizedParam[] = useMemo(() => {
    const normalize = (param: string) =>
      param.replaceAll("_", " ").replaceAll("()", "").trim();

    return params.map((param: string) => ({
      key: param,
      name: normalize(param),
    }));
  }, [params]);

  const defaultYAxis = useMemo(() => {
    const res: NormalizedParam[] = [];
    if (yAxis !== undefined) {
      return yAxis
        .split(",")
        .map((y) => normalized.at(parseInt(y)))
        .filter((param) => param !== undefined) as
        | NormalizedParam[]
        | undefined;
    }

    yAxisSearches.forEach((pattern) => {
      const match = normalized.find((param) => param.key.match(pattern));
      if (match !== undefined && !res.includes(match)) {
        res.push(match);
      }
    });
    return res;
  }, [normalized, yAxis]);

  const defaultXAxis = useMemo(() => {
    if (xAxis !== undefined) {
      return normalized.find((param) => param.key === xAxis);
    }

    let res = undefined;
    xAxisSearches.some((pattern) => {
      res = normalized.find((param) => param.key.match(pattern));
      return res !== undefined;
    });

    return res;
  }, [normalized, xAxis]);

  return { normalized, defaultYAxis, defaultXAxis };
}
