import { AxiosError } from "axios";
import { createContext, ReactNode, useEffect, useState } from "react";
import { flushSync } from "react-dom";
import { useParams, useSearchParams } from "react-router-dom";
import useAuthData from "../hooks/use-auth-data";
import useFile from "../hooks/use-file";
import { File } from "../types/api/files";
import { Log } from "../types/api/logs";

interface LogState {
  loading: boolean;
  data: Log;
  error?: AxiosError;
}

interface FileState {
  loading: boolean;
  data: File;
  error?: AxiosError;
}

const InitialFileState = {
  loading: true,
  data: {} as File,
};

const InitialLogState = {
  loading: true,
  data: {} as Log,
};

export const LogContext = createContext<{
  log: LogState;
  file: FileState;
  selectedFile?: string;
  charts: Set<string>;
  handleFileSelected: (file: File) => void;
  addChart: (chart: string) => void;
  removeChart: (chart: string) => void;
}>({
  log: InitialLogState,
  file: InitialFileState,
  charts: new Set(["main"]),
  handleFileSelected: () => {},
  addChart: () => {},
  removeChart: () => {},
});

export const LogProvider = ({ children }: { children: ReactNode }) => {
  const { logId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedFile, setSelectedFile] = useState(
    () => searchParams.get("file") ?? undefined
  );
  const [charts, setCharts] = useState(() => {
    if (searchParams.has("charts")) {
      return new Set(searchParams.get("charts")?.split(","));
    }

    return new Set(["main"]);
  });
  const logState: LogState = useAuthData<Log>(`/v1/logs/${logId}`);
  const fileState: FileState = useFile(selectedFile, 2000);

  useEffect(() => {
    if (!logState.error && !logState.loading && selectedFile === undefined) {
      setSelectedFile(logState.data.files.at(0)?.id);
    }
  }, [logState, selectedFile]);

  const handleFileSelected = (file: File) => {
    setSearchParams((prev) => {
      prev.set("file", file.id);
      return prev;
    });

    setSelectedFile(file.id);
  };

  const addChart = (chartId: string) => {
    const newCharts = charts.add(chartId);
    setSearchParams((prev) => {
      prev.set("charts", Array.from(newCharts).join(","));
      return prev;
    });

    setCharts(newCharts);
  };

  const removeChart = (chartId: string) => {
    const newCharts = new Set(charts);
    newCharts.delete(chartId);

    flushSync(() => {
      setSearchParams((prev) => {
        prev.set("charts", Array.from(newCharts).join(","));
        return prev;
      });
      setCharts(newCharts);
    });
  };

  return (
    <LogContext.Provider
      value={{
        log: logState,
        file: fileState,
        charts,
        selectedFile,
        addChart,
        removeChart,
        handleFileSelected,
      }}
    >
      {children}
    </LogContext.Provider>
  );
};
