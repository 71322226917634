import { CallSplit, Close, LinearScale, Refresh } from "@mui/icons-material";
import {
  Grid2,
  IconButton,
  IconButtonProps,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { ReactNode, useCallback, useMemo } from "react";
import LogChart from "../../../components/LogChart";
import ParameterSelector from "../../../components/ParameterSelector";
import XAxisSelector from "../../../components/XAxisSelector";
import useChart from "../../../hooks/use-chart";
import { File } from "../../../types/api/files";

export interface ChartContainerProps {
  containerId: string;
  file: File;
  maxHeight: number;
  onDelete?: (id: string) => void;
}

export function ChartContainerLoading({ maxHeight }: { maxHeight: number }) {
  return (
    <Grid2 size={{ xs: 12 }}>
      <Grid2
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid2 container spacing={1} size={{ xs: 12, sm: 12, md: 6 }}>
          <Skeleton variant="rounded" height={50} width="100%" />
        </Grid2>
        <Grid2
          container
          spacing={1}
          size={{ xs: 12, sm: 12, md: 6 }}
          justifyContent="flex-end"
        >
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="circular" width={40} height={40} />
        </Grid2>
        <Grid2
          container
          justifyContent="center"
          width="100%"
          sx={{
            touchAction: "pan-y",
          }}
        >
          <Skeleton variant="rounded" width="100%" height={maxHeight} />
        </Grid2>
      </Grid2>
    </Grid2>
  );
}

interface ChartOptionButton extends IconButtonProps {
  icon?: ReactNode;
}

export default function ChartContainer(props: ChartContainerProps) {
  const { file, maxHeight, containerId, onDelete } = props;
  const {
    hasDefaultOptions,
    autoScale,
    normalized,
    split,
    yAxis,
    xAxis,
    toggleAutoScale,
    toggleSplit,
    handleXAxisChange,
    handleYAxisChange,
    handleResetOptions,
  } = useChart(containerId, file);

  const charts = useMemo(() => {
    const defaultProps = {
      data: file.data ?? [],
      params: yAxis ?? [],
      xAxis: xAxis ?? undefined,
      legend: true,
      autoScale,
      syncId: containerId,
    };

    if (!split) {
      return (
        <LogChart
          {...defaultProps}
          containerProps={{
            aspect: 2.3,
            width: "100%",
            maxHeight,
          }}
        />
      );
    }

    return yAxis?.map((param) => (
      <Grid2 key={param.key} size={{ xs: 12, md: 6, lg: 4 }}>
        <LogChart
          {...defaultProps}
          params={[param]}
          containerProps={{
            aspect: 1.7,
            width: "100%",
            maxHeight,
          }}
        />
      </Grid2>
    ));
  }, [file.data, autoScale, maxHeight, yAxis, split, xAxis, containerId]);

  const handleDelete = useCallback(() => {
    handleResetOptions();
    if (onDelete !== undefined) {
      onDelete(containerId);
    }
  }, [onDelete, handleResetOptions, containerId]);

  const makeIconButton = ({ title, icon, ...rest }: ChartOptionButton) => (
    <Tooltip title={title}>
      <span>
        <IconButton title={title} {...rest}>
          {icon}
        </IconButton>
      </span>
    </Tooltip>
  );

  const toggleSplitBtn = useMemo(
    () =>
      makeIconButton({
        title: "Toggle Split Charts",
        onClick: toggleSplit,
        icon: <CallSplit />,
        color: split ? "success" : "default",
      }),
    [toggleSplit, split]
  );

  const toggleAutoScaleBtn = useMemo(
    () =>
      makeIconButton({
        title: "Toggle Auto Scale",
        onClick: toggleAutoScale,
        icon: <LinearScale />,
        color: autoScale ? "success" : "default",
      }),
    [toggleAutoScale, autoScale]
  );

  const resetChartOptionsBtn = useMemo(
    () =>
      makeIconButton({
        title: "Reset Chart Options",
        onClick: handleResetOptions,
        icon: <Refresh />,
        disabled: hasDefaultOptions,
      }),
    [handleResetOptions, hasDefaultOptions]
  );

  const deleteChartBtn = useMemo(
    () =>
      makeIconButton({
        title: "Delete Chart",
        onClick: handleDelete,
        icon: <Close />,
        disabled: containerId === "main" || !onDelete,
      }),
    [handleDelete, containerId, onDelete]
  );

  return (
    <Grid2 size={{ xs: 12 }}>
      <Grid2
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid2 container spacing={1} size={{ xs: 12, sm: 12, md: 6 }}>
          <XAxisSelector
            params={normalized}
            selected={xAxis}
            onChange={handleXAxisChange}
          />
        </Grid2>
        <Grid2
          container
          spacing={1}
          size={{ xs: 12, sm: 12, md: 6 }}
          justifyContent="flex-end"
        >
          {toggleSplitBtn}
          {toggleAutoScaleBtn}
          <ParameterSelector
            params={normalized}
            value={yAxis || []}
            onChange={handleYAxisChange}
          />
          {resetChartOptionsBtn}
          {deleteChartBtn}
        </Grid2>
        <Grid2
          container
          justifyContent="center"
          width="100%"
          sx={{
            touchAction: "pan-y",
          }}
        >
          {charts}
        </Grid2>
      </Grid2>
    </Grid2>
  );
}
