import { Add, Close, DoDisturb, Search } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  Grid2,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { AxiosError } from "axios";
import { Fragment, useContext, useState } from "react";
import Page from "../../../components/Page";
import { LogContext, LogProvider } from "../../../context/log";
import useViewportResize, { calcMaxHeight } from "../../../hooks/use-viewport";
import { File } from "../../../types/api/files";
import Layout from "../layout";
import ChartContainer, { ChartContainerLoading } from "./ChartContainer";

interface FileSelectorProps {
  files: File[];
  selected?: string;
  onSelected: (file: File) => void;
}

function FileSelector(props: FileSelectorProps) {
  const { files, selected, onSelected } = props;

  const handleChange = (event: SelectChangeEvent<string>) => {
    const file = files.find((file: File) => file.id === event.target.value);

    if (file !== undefined) {
      onSelected(file);
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="log-file-selector-label">File</InputLabel>
      <Select
        labelId="log-file-selector-label"
        id="log-file-selector"
        value={selected ?? ""}
        label="File"
        onChange={handleChange}
        variant="outlined"
      >
        {files.map((file) => (
          <MenuItem key={file.id} value={file.id}>
            {file.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

interface LogErrorProps {
  error: AxiosError;
}

function LogError(props: LogErrorProps) {
  const { error } = props;
  const theme = useTheme();

  if (error.code === "ERR_CANCELED") {
    return null;
  }

  const getErrorText = () => {
    switch (error.status) {
      case 403:
        return {
          Icon: DoDisturb,
          text: "Sorry, you're not allowed to access this log.",
        };
      case 404:
        return {
          Icon: Search,
          text: "Sorry, the log you're looking for does not exist.",
        };
      default:
        return {
          Icon: Close,
          text: "Something went wrong, please try again later.",
        };
    }
  };

  const { Icon, text } = getErrorText();

  return (
    <Grid2
      size={{ xs: 12 }}
      justifyContent="center"
      alignItems="center"
      display="inline-flex"
    >
      <Icon
        fontSize="large"
        color="error"
        sx={{ marginRight: theme.spacing(1) }}
      />
      <Typography variant="body1">{text}</Typography>
    </Grid2>
  );
}

function FileProcessing() {
  const theme = useTheme();

  return (
    <Grid2
      size={{ xs: 12 }}
      justifyContent="center"
      alignItems="center"
      display="inline-flex"
    >
      <CircularProgress
        size={30}
        variant="indeterminate"
        sx={{ marginRight: theme.spacing(1) }}
      />
      <Typography variant="body1">This file is still processing...</Typography>
    </Grid2>
  );
}

function LogContainer() {
  const theme = useTheme();
  const [maxHeight, setMaxHeight] = useState(
    calcMaxHeight(window.visualViewport, 400)
  );
  const {
    log,
    file,
    selectedFile,
    handleFileSelected,
    charts,
    addChart,
    removeChart,
  } = useContext(LogContext);
  const error = log.error ?? file.error ?? undefined;

  useViewportResize((viewport) => {
    setMaxHeight(calcMaxHeight(viewport, 400));
  });

  const handleAddChart = () => {
    addChart((Math.random() + 1).toString(36).substring(7));
  };

  return (
    <Layout>
      <Page
        loading={log.loading}
        pageTitle={log.data.name || "Log"}
        title={log.data.name || "Log"}
        action={
          <Box sx={{ maxWidth: `calc(100vw - ${theme.spacing(2)})` }}>
            <Grid2 container spacing={1} alignItems="center">
              <Grid2 flexGrow={1}>
                <FileSelector
                  files={log.data.files || []}
                  selected={selectedFile}
                  onSelected={handleFileSelected}
                />
              </Grid2>
              <Grid2>
                <Tooltip title="Add Chart">
                  <IconButton onClick={handleAddChart}>
                    <Add />
                  </IconButton>
                </Tooltip>
              </Grid2>
            </Grid2>
          </Box>
        }
      >
        {!error &&
          file.loading &&
          Array.from(charts).map((chart) => (
            <ChartContainerLoading key={chart} maxHeight={maxHeight} />
          ))}

        {!error &&
          !!file.data.processed_at &&
          !file.loading &&
          Array.from(charts).map((chart) => (
            <Fragment key={chart}>
              <ChartContainer
                containerId={chart}
                file={file.data}
                maxHeight={maxHeight}
                onDelete={removeChart}
              />
              <Divider variant="fullWidth" />
            </Fragment>
          ))}

        {((!log.loading && !!error) ||
          (!file.loading && !file.data.processed_at)) && (
          <Box
            component={Paper}
            elevation={0}
            sx={{
              backgroundColor:
                theme.palette.mode === "dark"
                  ? theme.palette.grey[900]
                  : theme.palette.grey[100],
              borderRadius: 2,
              width: "100%",
              height: maxHeight,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!error && <FileProcessing />}
            {!!error && <LogError error={error} />}
          </Box>
        )}
      </Page>
    </Layout>
  );
}

export default function Logs() {
  return (
    <LogProvider>
      <LogContainer />
    </LogProvider>
  );
}
